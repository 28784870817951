import { useMsal } from '@azure/msal-react';
import React, { useState } from 'react';
import { ReactComponent as TextLogoutIcon } from '../../assets/textLogout2.svg';
import { ReactComponent as TextFlechaIcon } from '../../assets/arrowmenu.svg';
import off from '../../assets/off.svg';

const SignOutButton = ({ name }) => {
    const { instance } = useMsal();

    const handleLogout = () => {
        // Remove the active link from localStorage
        localStorage.removeItem('activeLink');
        
        instance.logoutPopup().catch(e => {
            console.error(e);
        });
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className="navbar-container">
            <button className="navbar-btn" onClick={toggleDropdown}>
                <TextLogoutIcon className="icono-logout" />
                <div className='text'><h2>{name}</h2></div>
                <TextFlechaIcon className="arrow" alt="arrow" />
            </button>
            {isDropdownOpen && (
                <div className="dropdown-menu">
                    <button className="dropdown-item" onClick={handleLogout}><img src={off} className="icono" alt="icono" />Cerrar sesión</button>
                </div>
            )}
        </div>
    );
};

export default SignOutButton;
