import React, { useState, useEffect, useRef, useMemo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import PrevIcon from '../../../assets/prevpage.svg'
import NextIcon from '../../../assets/nextpage.svg'
import { useCSSVariable } from '../../../utils/Colors';

const GraphContConv = ({ id,Url }) => {

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [maxValue, setMaxValue] = useState(0);
  const [maxGlobalValue, setMaxGlobalValue] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [availableMonths, setAvailableMonths] = useState([]);
  const containerRef = useRef(null);
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  
/*Responsive container takes container dimensions for resizes */
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const width = containerRef.current.clientWidth-(containerRef.current.clientWidth/7);
        const height = containerRef.current.clientHeight-(containerRef.current.clientHeight/7);
        const size = Math.min(width, height);
        setDimension({ width: size, height: size });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  /*Color Palette call */
  const COLORS = [  
  useCSSVariable('--darkblue'),
  useCSSVariable('--lightblue'),
  useCSSVariable('--Lightpink'),
  useCSSVariable('--middlegray'),
  useCSSVariable('--pink'),
  useCSSVariable('--blue'),
  useCSSVariable('--bluse'),
  useCSSVariable('--pinkselected'),
  useCSSVariable('--Darkpink')];

  /*All months array*/
  const monthNames = useMemo(() => [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ], []);

/*Data Obtain */
  useEffect(() => {
    fetch(Url)
      .then(response => response.json())
      .then(data => {
        const formattedData = data.map(item => ({
          name: `${monthNames[item.month - 1]}-${item.year}`,
          value: item.total_conversations,
          date: new Date(item.year, item.month - 1)
        }));
        const maxGlobal = formattedData.reduce((max, item) => Math.max(max, item.value), 0);
        setMaxGlobalValue(maxGlobal);
        setData(formattedData);
        setFilteredData(formattedData);
        const availableMonths = Array.from(new Set(data.map(item => `${item.year}-${String(item.month).padStart(2, '0')}`)));
        if (availableMonths.length > 0) {
          setSelectedMonth(availableMonths[0]);
          setStartDate(availableMonths[0]);
          setEndDate(availableMonths[availableMonths.length - 1]);
          setAvailableMonths(availableMonths);
        }
      })
      .catch((error) => {
      });

  }, [id, monthNames]);

  useEffect(() => {
    if (selectedMonth) {
      const [year, month] = selectedMonth.split("-");
      const monthIndex = parseInt(month, 10) - 1;
      const monthData = data.filter(item => item.date.getFullYear().toString() === year && item.date.getMonth() === monthIndex);
      const monthMaxValue = monthData.reduce((max, item) => Math.max(max, item.value), 0);
      setMaxValue(monthMaxValue);

    }
  }, [selectedMonth, data]);
  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    filterData(data, newStartDate, endDate);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    filterData(data, startDate, newEndDate);
  };
  const filterData = (data, newStartDate, newEndDate) => {
    if (!newStartDate || !newEndDate) return;

    const start = new Date(newStartDate);
    const end = new Date(newEndDate);
    end.setMonth(end.getMonth() + 1); 
    end.setDate(0);

    const filtered = data.filter(item => {
      const itemDate = new Date(item.date.getFullYear(), item.date.getMonth());
      return itemDate >= start && itemDate <= end;
    });

    setFilteredData(filtered);
  };


  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState([]);
  let itemsPerPage = 3;
  if (window.innerHeight <= 500) {
itemsPerPage=2;
  }

  useEffect(() => {
    const begin = currentPage * itemsPerPage;
    const end = begin + itemsPerPage;
    setCurrentData(filteredData.slice(begin, end));
  }, [currentPage, filteredData]);

  const nextPage = () => {
    if ((currentPage + 1) * itemsPerPage < filteredData.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
let fontsize="2vh";

if (window.innerHeight <= 500) {
  fontsize = '1.8vh'; 

}
if (window.innerHeight <= 390) {
  fontsize = '1.5vh'; 
}
  return (
    <text x={x} y={y} 
    fill="white" 
    textAnchor="middle" 
    dominantBaseline="central"
    fontSize={fontsize}
    fontFamily="Open Sans, sans-serif"
    fontWeight="bold" 
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
  return (
    <div ref={containerRef} className='GraphContConv' >
      <div className='titulo'>
          <h2>Conteo de conversaciones</h2>
      </div>
      <div  class="graphCont">
          <ResponsiveContainer width={(dimension.width)} height="95%">
                    {filteredData.length > 0 ? (
                      <PieChart>
                        <Pie
                          data={filteredData}
                          cx= "50%"
                          cy= "50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={'85%'}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    ) : (
                      <p>No existen datos...</p>
                    )}
                  </ResponsiveContainer>
      </div> 
      <div class="Filter">
        <div className='filtro'>
            <div className='container-input'>
                <txt>Fecha inicio</txt>
                <input
                  type="month"
                  value={startDate}
                  onChange={handleStartDateChange}
                  min={availableMonths[0]}
                  max={endDate} 
                />
            </div>
        </div>
        <div className='filtro'>
            <div className='container-input'>
                <txt>Fecha final</txt>
                <input
                  type="month"
                  value={endDate}
                  onChange={handleEndDateChange}
                  min={startDate} 
                  max={availableMonths[availableMonths.length - 1]}
                />
            </div>
        </div>
    </div>
      <div class="tablecount">
      <div className="table-container">
          <table>
              <thead className='txttitulo'>
                <tr style={{}}>
                  <th>Mes</th>
                  <th>Sesiones</th>
                  </tr>
                </thead>
                  <tbody>
                  {currentData.map((item, index) => (
                  <tr key={item.name}>
                      <td className='txtnum' >
                        <span className="color-circle"
                          style={{ backgroundColor: COLORS[(currentPage * itemsPerPage + index) % COLORS.length] }}
                        ></span>
                        {item.name}
                          </td>
                          <td className='txtnum'>{item.value}</td>
                          </tr>
                          ))}
                        </tbody>
                        </table>
                        <div className="pagination-controls">
                        <button onClick={prevPage} disabled={currentPage === 0}>
                        <img   className='icon' src={PrevIcon} alt="Previous" />
                        </button>
                        <button onClick={nextPage} disabled={(currentPage + 1) * itemsPerPage >= data.length}>
                        <img  className='icon' src={NextIcon} alt="Next" />
                        </button>
                      </div>
          </div>
      </div>
    </div>
        );
      };

export default GraphContConv;