import React, { useState, useEffect } from "react";
import IconoBot from "../../../utils/IconoBot";
import IconoUser from "../../../utils/IconoUser";
import UrlProvider from "../../../Api/UrlProvider";

const TabHistoMsg = ({ id, Url}) => {

  /*Default date*/
  const defaultStartDate = '2024-03-20'; 
  const defaultEndDate = '2024-03-21';
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  

  /*Abbreviated text, it takes up two or three letters after the spaces*/
  const displayAbbreviation = (name) => {
    if (name.includes(" ")) {
      const index = name.indexOf(" ");
      return name.substring(0, index) + ' ' + name.substring(index + 1, index + 4); 
    }
    return name;
  };

  /* Data Obtain */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await   fetch(Url);
        const rawData = await response.json();

        let tempData = rawData.map((item) => ({
          de: item["DE"],
          companyName: item["Company Name"],
          fecha: item["Fecha"],
          mensaje: item["Mensaje"],
          companyId: item["Company ID"],
          conversacionId: item["Conversation Id"],
        }));

        setData(tempData);
      } catch (error) {
      }
    };

    fetchData();
  }, [id]);

  /*Defines dates of timepicker */
  useEffect(() => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const filtered = data.filter(item => {
      const itemDate = new Date(item.fecha);
      return (!start || itemDate >= start) && (!end || itemDate <= end);
    });

    setFilteredData(filtered);
  }, [data, startDate, endDate]);

  const dateFormatter = (dateString) => {
    const date = new Date(dateString);
    if (date.getUTCHours() === 0) {
      date.setUTCDate(date.getUTCDate() + 1);
    }
    return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(date);
  };

  return (
    <div className="TabHistoriMensajesU">
      <div className="titulo">
        <h2>Historial de mensajes</h2>
      </div>
      <div className="filtro">
        <div className='container-input'>
          <txt>Fecha inicio</txt>
          <input
            type="date" 
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Fecha inicial"
          />
        </div>
        <div className='container-input'>
          <txt>Fecha final</txt>
          <input
            type="date" 
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="Fecha final"
          />
        </div>
      </div>
      {filteredData.length > 0 ? (
        <div className="table-container">
          <table>
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "60%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>DE</th>
                <th>FECHA</th>
                <th>MENSAJE</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={`${item.conversacionId}-${index}`}>
                  <td className="company">
                     {displayAbbreviation(item.companyName)}
                    {item.de === "BOT" ? (
                      <IconoUser className="iconoUserSmall" />
                    ) : (
                        <IconoBot className="iconoBotSmall" />
                    )}
                    <txt className="de">{item.de}</txt>
                  </td>
                  <td className="itemfecha">{dateFormatter(item.fecha)}</td>
                  <td className="mensaje">{item.mensaje}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="sticky-footer">

          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default TabHistoMsg;