import React, { useState, useEffect, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import UrlProvider from '../../../Api/UrlProvider';
import { useCSSVariable } from '../../../utils/Colors';

//Default date
const GraphTotalInfoClients = ({ id }) => {
  /*Dafault date */
  const defaultStartDate =new Date(); ; 
  const defaultEndDate = new Date(defaultStartDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const formattedCurrentDate = defaultStartDate.toLocaleDateString('en-CA');
  const formattedOneWeekAgo = defaultEndDate.toLocaleDateString('en-CA');

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(formattedOneWeekAgo);
  const [endDate, setEndDate] = useState(formattedCurrentDate);
  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('');

/*Responsive*/
const [chartSize, setChartSize] = useState(300);
const refDiv = useRef(null);

useEffect(() => {
  const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
      requestAnimationFrame(() => {
        
        let newSize = (
          Math.min(entry.contentRect.width, ((entry.contentRect.height)-entry.contentRect.height/2.8))
        );

        if (window.innerHeight <= 500) {
          newSize = Math.min(entry.contentRect.width, ((entry.contentRect.height)-entry.contentRect.height/3.2));
        }
        if (window.innerHeight <= 390) {
          newSize = Math.min(entry.contentRect.width, ((entry.contentRect.height)-entry.contentRect.height/3.1));
        }

        setChartSize(newSize);

      });
    }
  });
resizeObserver.observe(refDiv.current);
return () => {
  resizeObserver.disconnect();
};
}, []);


  //abbreviation 
  const displayAbbreviation = (name) => {
    if (name.includes(" ")) {
      const index = name.indexOf(" ");
      return name.substring(0, index) + ' ' + name.substring(index + 1, index + 4);
    }
    return name;
  };

  /*Color Palette */
  const Lightpink = useCSSVariable('--Lightpink');
  const blue = useCSSVariable('--blue');
  const Ultralightblue = useCSSVariable('--middlegray');
  const text = useCSSVariable('--text');
  const title= useCSSVariable ('--subtitle')
  
  
  /*Cotumize leyend */
  const renderLegend = (props) => {
    const { payload } = props;
    let fontSize = '70%'; 
    let marginTop= 5;
    let marginRight='5%';
    let circleCx = '5';
    let circleCy = '5';
    let circleR = '5';
    let marginBottom= '1%';

    if (window.innerHeight <= 500) {
      fontSize = '1.6vh'; 
      marginTop= -10;
      circleCx = '4';
      circleCy = '4';
      circleR = '4';
    }
    if (window.innerHeight <= 390) {
      fontSize = '1.6vh';
      marginTop= -10;
      circleCx = '3.5';
      circleCy = '3.5';
      circleR = '3.5';
    }
    return (
      <ul style={{
        listStyle: 'none',
        margin: 0,
        padding: 0,
        display: 'flex', 
        justifyContent: 'center',
        flexWrap: 'wrap'
      }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{
            display: 'flex',
            alignItems: 'center',
            marginRight, 
            marginBottom,
            marginTop
          }}>
            <svg height="10" width="10">
              <circle cx={circleCx} cy={circleCy} r={circleR}  fill={entry.color} />
            </svg>
            <span style={{ marginLeft: 3, fontSize, fontWeight: '600', color: title }}>{entry.value}</span>
          </li>
        ))}
      </ul>
      
    );
  };

  
  const renderCustomBarLabel = (props) => {
    const { x, y, width, index } = props;
    
    if (selectedClientId == '') {
      const clientName = filteredData[index].Nombre_de_Compania;
      return (
        <text 
          x={x + width / 2} 
          y={y - 10} 
          fill={text}
          fontSize="65%" 
          fontFamily="Open Sans" 
          fontWeight="500" 
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {clientName}
        </text>
      );
    }
  };
  
  
//Generate a format for a date
  const dateFormatter = (dateString) => {
    const date = new Date(dateString);
    if (date.getUTCHours() === 0) {
      date.setUTCDate(date.getUTCDate() + 1);
    }
    return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(date);
  };



  //Data obtain
  useEffect(() => {
    fetch(`${UrlProvider.defaults.baseURL}/conv_messages_super/${id}`)
      .then(response => response.json())
      .then(data => {
        if (!Array.isArray(data)) {
          console.error('Expected an array, but received:', data);
          return;
        }
        setData(data);

        const uniqueClients = [...new Set(data.map(item => item.company_id))]
            .map(company_id => {
              const company = data.find(item => item.company_id === company_id);
              return {
                id: company_id,
                name: company.Nombre_de_Compania, 
              };
            });

        setClients(uniqueClients);

      })
  }, [id]);

  //Totals in range of time
  const [totalByClient, setTotalByClient] = useState({});
  const calculateTotalByClient = () => {
    const totals = {};
    
    if (!selectedClientId) {
      filteredData.forEach(item => {
        const date = new Date(item.date);
        const formattedDate = dateFormatter(date);
        
        if (!totals[formattedDate]) {
          totals[formattedDate] = {
            totalUsers: 0,
            totalMessages: 0,
            totalConversations: 0,
            date: formattedDate
          };
        }
        totals[formattedDate].totalUsers++;
        totals[formattedDate].totalMessages += item.message_count;
        totals[formattedDate].totalConversations += item.Conversaciones;
      });
    }
  

    setTotalByClient(totals);
  };
  
  useEffect(() => {
    calculateTotalByClient();
  }, [filteredData]);
  useEffect(() => {
    setTotalByClient({}); 
  }, [selectedClientId]);

 //filter date
 useEffect(() => {
  let filtered = Array.isArray(data) ? data : [];
  const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    if (startDate) {
      filtered = filtered.filter(item => new Date(item.date) >= start);
    }
    if (endDate) {
      filtered = filtered.filter(item => new Date(item.date) <= end);
    }
  if (selectedClientId) {
    filtered = filtered.filter(item => {
      return item.company_id.toString() === selectedClientId.toString();
    });
  }

  setFilteredData(filtered);
}, [data, startDate, endDate, selectedClientId]);
  
if (!data) return <div>Loading...</div>;


  //Data size adjustment variable
  const handleFocus = (e) => {
    e.target.type = 'date';
  };

  const handleBlur = (e) => {
    if (e.target.value === '') {
      e.target.type = 'text';
    }
  };
  
  function rendergraph(){
    /*Render graph for Clients */
    let yAxisFontSize = '1.5vh';
    if (window.innerHeight <= 500) {
      yAxisFontSize = '1.8vh';
    }
    if (window.innerHeight <= 390) {
      yAxisFontSize = '1.8vh';
    }

    if (selectedClientId) {
      return (
        <>   {/* RECHARTS */}
          <ResponsiveContainer width="100%"  height={chartSize}>
          <BarChart data={filteredData} >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={dateFormatter} tick={{ fontSize: yAxisFontSize }}   />
            <YAxis tick={{ fontSize: yAxisFontSize }}  />
            <Tooltip />

            <Legend content={renderLegend}/>
            <Bar dataKey="Cantidad_Usuarios" fill={Ultralightblue} name="Total Usuarios" >
            </Bar>
            <Bar dataKey="message_count" fill={blue} name="Total Mensajes">
              {selectedClientId && <LabelList content={renderCustomBarLabel} />}
            </Bar>
            <Bar dataKey="Conversaciones" fill={Lightpink} name="Total Conversaciones">
            </Bar>

          </BarChart>
        </ResponsiveContainer>

      </>
      );
    } else if (!selectedClientId) {
      return (
        <>   {/* RECHARTS */}
          <ResponsiveContainer width="100%"  height={chartSize}>
           <BarChart  data={Object.values(totalByClient)}>
             <CartesianGrid strokeDasharray="3 3" />
             <XAxis dataKey="date" tick={{ fontSize: yAxisFontSize}} />
             <YAxis tick={{ fontSize: yAxisFontSize }} />

             <Tooltip />
 
             <Legend content={renderLegend}/>
             <Bar dataKey="totalUsers" fill={Ultralightblue} name="Total Usuarios" >
             </Bar>
             <Bar dataKey="totalMessages" fill={blue} name="Total Mensajes">
              {selectedClientId && <LabelList content={renderCustomBarLabel} />}
             </Bar>
             <Bar dataKey="totalConversations" fill={Lightpink} name="Total Conversaciones">
             </Bar>
 
           </BarChart>
         </ResponsiveContainer>
 
       </>
      );
    }
  } 

  return (
    <div ref={refDiv} className='GraphBarsSuper' >
    <div className='titulo'>
      <h2>Comportamiento clientes</h2>
    </div>

    <div className='filtro'>
        <div className='first'>
          <div className='container-input'>
              <txt>
              Fecha inicio
              </txt>
          
              <input
              type="date"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              
            />
          </div>
          <div className='container-input'>
                  <txt>
                  Fecha Final
                  </txt>
                
                <input
                  type="date"
                  onFocus={handleFocus} 
                  onBlur={handleBlur}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
            />
            </div>
      </div>
            <div className='second'>
              <div className='container-input'> 
                <txt>
                  Cliente
                  </txt>  
                  <select
                  value={selectedClientId}
                  onChange={(e) => setSelectedClientId(e.target.value)}
                >
                  <option value="">Todos</option>
                    {clients.map(client => (
                      <option key={client.id} value={client.id}>
                        {displayAbbreviation(client.name)}
                      </option>
                  ))}
                </select>
                </div>
        </div>
    </div>

      <div className='containerBar'>
      {rendergraph()}
      </div>
    </div>
  );
};

export default GraphTotalInfoClients;
