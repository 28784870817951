import React from 'react';

/*Main boards and role clasification*/
import Board_ExplorerClient from './Boards/Explorer/Board_ExplorerClient';
import Board_ExplorerUser from './Boards/Explorer/Board_ExplorerUser';
import Board_ExplorerAdmin from './Boards/Explorer/Board_ExplorerAdmin';
import ConstantsComponent from '../claims/Constants';

function Explorer() {
  // Retrieve role type from ConstantsComponent
  const { TYPE_ID} = ConstantsComponent();
  // Determine which board to display based on the role type ID
  const mostrarBoard_ExplorerClient = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 3;
  const mostrarExplorerUser = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 2;
  const mostrarExplorerAdmin = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 1;

  return (
    /*Conditionally render the appropriate board based on the role type*/
    <div>
      
      {mostrarBoard_ExplorerClient && <Board_ExplorerClient />}
      {mostrarExplorerUser && <Board_ExplorerUser />}
      {mostrarExplorerAdmin && <Board_ExplorerAdmin />}
    </div>
  );
}

export default Explorer;
