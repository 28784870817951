import TuIcono from '../../assets/logo_exten.svg';
import MyIcon from '../../assets/Logo_comprimido.svg';
import Companyinf from './Companyinf';
import ConstantsComponent from '../../claims/Constants';
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import * as Icons from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaSearch } from "react-icons/fa";
import { AiFillPieChart } from "react-icons/ai";
import { useSidebarContext } from './SidebarContext';
import { isMobile } from 'react-device-detect';

const Sidebar = () => {
  const { COMPANY, PROGRAM } = ConstantsComponent();

  const company = {
    program: PROGRAM,
    company: COMPANY
  };

  const { isCollapsed, setIsCollapsed } = useSidebarContext();
  const [activeLink, setActiveLink] = useState('/Datav/statistics');
  const [showMenuIcon, setShowMenuIcon] = useState(false);

  const handleSetActiveLink = (route) => {
    setActiveLink(route);
    localStorage.setItem('activeLink', route);
  };

  useEffect(() => {
    setShowMenuIcon(!isMobile); 
    const savedActiveLink = localStorage.getItem('activeLink');
    if (savedActiveLink) {
      setActiveLink(savedActiveLink);
    } else {
      setActiveLink('/Datav/statistics'); // Set the default link if no value is found in localStorage
    }
  }, []);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="menu-item">
        {showMenuIcon && (
          <GiHamburgerMenu onClick={toggleSidebar} className={`menu-icon ${isCollapsed ? 'colapsado' : 'expandido'}`} />
        )}
      </div>

      <div className="menu-item">
        {isCollapsed
          ? <div className="information_program"></div>
          : <Companyinf 
              title={company.program}
              count={company.company} />
        }
      </div>

      <ul>
        <div className="menu-item">
          <li className={activeLink === '/Datav/statistics' ? 'active' : ''} onClick={() => handleSetActiveLink('/Datav/statistics')}>
            <Link to="/Datav/statistics"><Icons.BsBarChartFill />
              {!isCollapsed && <span className="TextOptions">Estadísticas </span>}
            </Link>
          </li>
        </div>
      </ul>

      <ul>
        <div className="menu-item">
          <li className={activeLink === '/Datav/PowerBi' ? 'active' : ''} onClick={() => handleSetActiveLink('/Datav/PowerBi')}>
            <Link to="/Datav/PowerBi"><AiFillPieChart />
              {!isCollapsed && <span className="TextOptions">Estadísticas contenido</span>}
            </Link>
          </li>
        </div>
      </ul>

      <ul>
        <div className="menu-item">
          <li className={activeLink === '/Datav/explorer' ? 'active' : ''} onClick={() => handleSetActiveLink('/Datav/explorer')}>
            <Link to="/Datav/explorer"><FaSearch />
              {!isCollapsed && <span className="TextOptions">Explorador</span>}
            </Link>
          </li>
        </div>
      </ul>

      <div className="menu-item">
        {isCollapsed
          ? <img src={MyIcon} className="icono colapsado" alt="icono colapsado" />
          : <img src={TuIcono} className="icono expandido" alt="Icono expandido" />}
      </div>
    </div>
  );
};

export default Sidebar;
