import React, { useState, useEffect } from "react";
import IconoBot from "../../../utils/IconoBot";
import IconoUser from "../../../utils/IconoUser";
import Select from "react-select";
import SearchIcon from "../../../utils/SearchIcon";
import { IoMdArrowDropdown } from "react-icons/io";

const TabHistoMsg = ({ id, Url }) => {
  const currentDate = new Date();
  const weekAgoDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const defaultStartDate = weekAgoDate.toISOString().split('T')[0]; // Formato 'YYYY-MM-DD'
  const defaultEndDate = currentDate.toISOString().split('T')[0]; // Formato 'YYYY-MM-DD'
  console.log("end",currentDate);
  console.log("endconvert",defaultEndDate);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [deFilter, setDeFilter] = useState("");
  const [deOptions, setDeOptions] = useState([]);
  const [isDateDescending, setIsDateDescending] = useState(true);

  const displayAbbreviation = (name) => {
    if (name.includes(" ")) {
      const index = name.indexOf(" ");
      return name.substring(0, index) + ' ' + name.substring(index + 1, index + 4);
    }
    return name;
  };

  const dateFormatter = dateString => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Url);
        const rawData = await response.json();
        const tempData = rawData.map(item => ({
          de: item["DE"],
          companyName: item["Company Name"],
          fecha: item["Fecha"],
          mensaje: item["Mensaje"],
          companyId: item["Company Id"],
          conversacionId: item["Conversation ID"],
        }));
        setData(tempData);
        const tempDeOptions = [...new Set(tempData.map(item => item.de))];
        setDeOptions(tempDeOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, Url]);

  useEffect(() => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    const filtered = data.filter(item => {
      const itemDate = new Date(item.fecha);
      const isDateInRange = (!start || itemDate >= start) && (!end || itemDate <= end);
      const isUserMatch = !deFilter || item["de"] === deFilter;
      return isDateInRange && isUserMatch;
    });

    const sorted = filtered.sort((a, b) => {
      if (isDateDescending) {
        return new Date(b.fecha) - new Date(a.fecha);
      } else {
        return new Date(a.fecha) - new Date(b.fecha);
      }
    });

    setFilteredData(sorted);
  }, [data, startDate, endDate, deFilter, isDateDescending]);

  const handleDateSortClick = () => {
    setIsDateDescending(!isDateDescending);
  };

  return (
    <div className="TabHistoriMensajesU">
      <div className="titulo">
        <h2>Historial de mensajes</h2>
      </div>
      <div className="filtro">
        <div className='first'>
          <div className='container-input'>
            <txt>Fecha inicio</txt>
            <input
              type="date"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              placeholder="Fecha inicial"
            />
          </div>
          <div className='container-input'>
            <txt>Fecha final</txt>
            <input
              type="date"
              value={endDate}
              onChange={e => setEndDate(e.target.value)}
              placeholder="Fecha final"
            />
          </div>
        </div>
        <div className='second'>
          <div className='container-inputc'>
            <txt2>Usuario</txt2>
            <Select
              classNamePrefix="my-select"
              className="select-containerC"
              value={deOptions.find(option => option.value === deFilter)}
              onChange={option => setDeFilter(option ? option.value : '')}
              options={deOptions.map(de => ({ value: de, label: de }))}
              placeholder={"Buscar usuario"}
              isClearable
              isSearchable
              components={{
                DropdownIndicator: () => (
                  <div style={{ display: 'flex', alignItems: 'center', marginInline:"0.5vw" ,width: '1vw', height: 'auto'}}>
                      <SearchIcon className="iconoUserSmall" />
                  </div>
                ),
              }}
              styles={{
                clearIndicator: provided => ({
                  ...provided,
                  color: 'gray',
                  width: '50%',
                  height: 'auto',
                }),
                singleValue: provided => ({
                  ...provided,
                  color: 'gray',
                  fontSize: '50%',
                  width: '100%',
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: 'gray',
                  fontSize: '50%',
                  width: '100%',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                  color: state.isSelected ? '#FF00B1' : 'grey',
                  cursor: 'pointer'
                }),
              }}
            />
          </div>
        </div>
      </div>
      {filteredData.length > 0 ? (
        <div className="table-container">
          <table>
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "60%" }} />
            </colgroup>

            <thead>
              <tr>
                <th>DE</th>
                <th>
                  <div onClick={handleDateSortClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                    FECHA <IoMdArrowDropdown style={{ transform: isDateDescending ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.2s' }} />
                  </div>
                </th>
                <th>MENSAJE</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={`${item.conversacionId}-${index}`}>
                  <td className="company">
                    {displayAbbreviation(item.companyName)}
                    {item.de === "BOT" ? (
                      <IconoBot className="iconoBotSmall" />
                    ) : (
                      <IconoUser className="iconoUserSmall" />
                    )}
                    <txt className="de">{item.de}</txt>
                  </td>
                  <td className="itemfecha">{dateFormatter(item.fecha)}</td>
                  <td className="mensaje" dangerouslySetInnerHTML={{ __html: item.mensaje }}></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="sticky-footer">
             <p
              style={{
              textAlign: 'start',
              fontSize: '50%',
              fontWeight:'700',
              color: '#687079',
              margin: '1%'
            }}
            >
            *Para visualizar información de un día debe seleccionar el día siguiente o anterior dependiendo si es la fecha inicial o fecha final
            </p>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default TabHistoMsg;
