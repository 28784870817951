
import React from 'react';
/*MSAL AUTH */
import { useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from'../components/Sidebar/Sidebar';
import Statistics from'../components/Statistics';
import Navbar from'../components/Navbar'
import Explorer from'../components/Explorer'
import { Routes, Route} from 'react-router-dom';
import { useSidebarContext } from '../components/Sidebar/SidebarContext';
import Popup from '../components/popup';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import PowerBi from'../components/PowerBi'

const Datav = () => {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const { isCollapsed } = useSidebarContext();
    const [showPopup, setShowPopup] = useState(!isMobile); 
    
    useEffect(() => {
        const handleOrientationChange = () => {
            if (isMobile && window.innerHeight > window.innerWidth) {
                //Show popup if it's a vertical orientation
                setShowPopup(true); 
            } else {
                //Hide popup in other orientation
                setShowPopup(false); 
            }
        };
    
        handleOrientationChange(); 
        //Change orientaation event
        window.addEventListener('resize', handleOrientationChange); 
        return () => window.removeEventListener('resize', handleOrientationChange);
    }, []);
    
    return (
        <div className={`datav-container ${isCollapsed ? 'sidebar-collapsed' : 'sidebar-expanded'}`}>
            {isAuthenticated ? (
                <>
                    {showPopup && <Popup onClose={() => setShowPopup(false)} />}
                    <div className='basepage'>
                        <Sidebar />
                        <Navbar />
                        <div className='distribution'>
                            <Routes>
                                <Route path="statistics" element={<Statistics />} />
                                <Route path="PowerBi" element={<PowerBi />} />
                                <Route path="explorer" element={<Explorer />} />
                            </Routes>
                        </div>
                    </div>
                </>
            ) : (
                navigate("/")
            )}
        </div>
    );
};

export default Datav;

