import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const EmbedDetailsContext = createContext();

export const EmbedDetailsProvider = ({ children }) => {
    const [embedDetails, setEmbedDetails] = useState({ embedUrl: '', embedToken: '' });

    const getEmbedDetails = async () => {
        try {
            const response = await axios.get('https://flaskapitutor.azurewebsites.net/api/obtener-detalles-reporte');
            console.log(response);
            setEmbedDetails({
                embedUrl: response.data.embedUrl,
                embedToken: response.data.embedToken
            });
        } catch (error) {
            console.error('Error fetching embed details:', error);
        }
    };

    useEffect(() => {
        getEmbedDetails();
        const intervalId = setInterval(getEmbedDetails, 3600000); // Actualiza cada hora
        return () => clearInterval(intervalId);
    }, []);

    return (
        <EmbedDetailsContext.Provider value={{ embedDetails }}>
            {children}
        </EmbedDetailsContext.Provider>
    );
};

export const useEmbedDetails = () => {
    const context = useContext(EmbedDetailsContext);
    if (!context) {
        throw new Error('useEmbedDetails must be used within an EmbedDetailsProvider');
    }
    return context;
};
