
import React from 'react';

/*Main boards and role clasification*/

import BoardCliente from './Boards/Statistics/Board_Client';
import BoardUser from './Boards/Statistics/Board_User';
import BoardSuper from './Boards/Statistics/Board_Super';
import ConstantsComponent from '../claims/Constants.jsx';

const Statistics = () => {
  /*Retrieve the role type from the ConstantsComponent */
  const { TYPE_ID} = ConstantsComponent();

  /*Determine which board to display based on the role type ID */
  const mostrarBoardCliente = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 3;
  const mostrarBoardUser = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 2;
  const mostrarBoardSuper = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 1;

  /*Conditionally render the appropriate board based on the role type */
  return (
    <div>
      {mostrarBoardCliente && <BoardCliente />}
      {mostrarBoardUser && <BoardUser />}
      {mostrarBoardSuper && <BoardSuper />}
    </div>
  );
};

export default Statistics;
