// Powerbi
import React, { useRef, useEffect } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useEmbedDetails } from './EmbedDetailsProvider';

const Powerbi = () => {
  const { embedDetails } = useEmbedDetails();
  const reportContainerRef = useRef(null);

  useEffect(() => {
    const removeBordersAndBars = () => {
      const iframe = reportContainerRef.current.querySelector('iframe');
      if (iframe) {
        iframe.style.border = 'none';

        const coverDiv = document.createElement('div');
        coverDiv.style.position = 'absolute';
        coverDiv.style.bottom = '0';
        coverDiv.style.left = '0';
        coverDiv.style.width = '100%';
        coverDiv.style.height = '40px'; // Ajusta la altura según sea necesario
        coverDiv.style.backgroundColor = 'white'; // Cambia el color aquí
        //coverDiv.style.border = '1px solid black'; 
        reportContainerRef.current.style.position = 'relative'; // Asegúrate de que el contenedor principal sea relativo
        reportContainerRef.current.appendChild(coverDiv);

        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        if (iframeDocument) {
          const tabStrip = iframeDocument.querySelector('.tab-strip');
          if (tabStrip) {
            tabStrip.style.display = 'none';
          }
        }
      }
    };

    const handleReportLoad = () => {
      removeBordersAndBars();
    };

    window.addEventListener('message', (event) => {
      if (event.data === 'powerbi_report_loaded') {
        handleReportLoad();
      }
    });

    return () => {
      window.removeEventListener('message', handleReportLoad);
    };
  }, []);

  return (
    <div ref={reportContainerRef} className="GraficaP" style={{ position: 'relative' }}>
      <PowerBIEmbed
        embedConfig={{
          type: 'report',
          id: 'b84ad80d-e519-4ffa-8541-9115404db28b',
          embedUrl: embedDetails.embedUrl,
          accessToken: embedDetails.embedToken,
          tokenType: models.TokenType.Aad,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              }
            },
            background: models.BackgroundType.Transparent,
          }
        }}
        eventHandlers={
          new Map([
            ['loaded', function () {
              console.log('Report loaded');
              window.postMessage('powerbi_report_loaded', '*');
            }],
            ['rendered', function () { console.log('Report rendered'); }],
            ['error', function (event) { console.log(event.detail); }],
            ['visualClicked', () => console.log('visual clicked')],
            ['pageChanged', (event) => console.log(event)],
          ])
        }
        cssClassName={"Tablero"}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      />
    </div>
  );
};

export default Powerbi;
