import ConstantsComponent from "../../../claims/Constants.jsx";
import TabHistoMsg from "../../Graphs/User/TabHistoUser.jsx";
import UrlProvider from "../../../Api/UrlProvider.jsx";

const Board_ExplorerUser= ()=>{
    //send user id to user explorer board
    const {ID} = ConstantsComponent();
    const Url= `${UrlProvider.defaults.baseURL}/historical_Single_user/${ID}`
    return (
            <div className="TabExplorerCliente_User">
                <TabHistoMsg id={ID} Url={Url}/>
            </div>
        
    );
};
export default Board_ExplorerUser;
//<TabHistoMsg id={ID} /> 