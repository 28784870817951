import GraphTotalInfoClients from "../../Graphs/Admin/GraphTotalInfoClients.jsx";
import InfoUserCard from "../../Graphs/Repeated/InfoUserCard.jsx";
import ConstantsComponent from "../../../claims/Constants.jsx";
import Card2 from "../../../components/Graphs/Admin/Card2.jsx";
import Cards from "../../../components/Graphs/Repeated/Cards.jsx";
import UrlProvider from "../../../Api/UrlProvider.jsx";
import Radar from "../../Graphs/Admin/RadarSuper.jsx";

const BoardSuper= ()=>{
    /*Claims */
    const { ROL, ID, NAME, EMAIL, TYPE_ID} = ConstantsComponent();
    /*New version */
    const Url_totalCM=`${UrlProvider.defaults.baseURL}/Total_messages_Admin/${ID}`;
    const KeyCMT="TotalMessages"
    const KeyCMB= "bot_response"
    const KeyCMU= "user_message"
    const DataCM= "Mensajes en total"

    /*Cards 
    const Url_totalCM=`${UrlProvider.defaults.baseURL}/total_Messages/${ID}`;
    const KeyCM="TotalMessages"
    const DataCM= "Mensajes en total"
    */
    
    const Url_totalC=`${UrlProvider.defaults.baseURL}/total_Conversations/${ID}`;
    const KeyC="TotalConversations"
    const DataC= "Conversaciones en total"

    const Url_totalCT=`${UrlProvider.defaults.baseURL}/total_clients/${ID}`;
    const KeyCT="TotalClientes"
    const DataCT= "Clientes en total"

    /*Radar */
    const Url_radar=`${UrlProvider.defaults.baseURL}/Activity_conversations_client/${ID}`
    
    /*Bars */
    const Url_bar= `${UrlProvider.defaults.baseURL}/conv_messages_super/${ID}`
    return (
            <div className="TabClient_Super">
                <InfoUserCard name={NAME} Email= {EMAIL} rol={ROL}/>
                <GraphTotalInfoClients id={ID} Url={Url_bar}/>
                <Radar id={ID} Url={Url_radar}/>
                <Cards id={ID} type_id={TYPE_ID} Key={KeyCT} Url={Url_totalCT} Data={DataCT} />
                <Card2 id={ID} type_id={TYPE_ID} Key1={KeyCMB} Key2={KeyCMU}  Url={Url_totalCM} Data={DataCM} />
                <Cards id={ID} type_id={TYPE_ID} Key={KeyC} Url={Url_totalC} Data={DataC} />
            </div>
        
    );
};
export default BoardSuper;