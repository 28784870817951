import ConstantsComponent from "../../../claims/Constants.jsx";
import GraphBars from "../../Graphs/Repeated/GraphBars.jsx";
import InfoUserCard from "../../Graphs/Repeated/InfoUserCard.jsx";
import GraphContConv from "../../Graphs/User/GraphContConv.jsx";
import Cards from "../../Graphs/Repeated/Cards.jsx";
import UrlProvider from "../../../Api/UrlProvider.jsx";

const BoardUser= ()=>{
    /*Claims */
    const { ROL, ID, NAME, EMAIL, TYPE_ID} = ConstantsComponent();
    /*Cards */
    const Url_totalMU=`${UrlProvider.defaults.baseURL}/TotalMessages_OnlyUser_by_day/${ID}`;
    const KeyMT="Total_Mensajes";
    const DataMT= "Mensajes en total"

    const Url_totalMC=`${UrlProvider.defaults.baseURL}/TotalConversations_OnlyUser_by_day/${ID}`;
    const KeyMC="Total_Conversaciones";
    const DataMC= "Conversaciones en total"
    
    /*Graph */
    const Url_messages=`${UrlProvider.defaults.baseURL}/MessagesConversations_OnlyUser_by_day/${ID}`
    const KeyDataM="message_count"
    const KeyDataC="Conversaciones"

    /*pie*/
    const Url= `${UrlProvider.defaults.baseURL}/count_conversations_day/${ID}`
    return (
            <div className="TabUser">
                <InfoUserCard name={NAME} Email= {EMAIL} rol={ROL}/>
                <GraphBars type_id={TYPE_ID} id={ID} dataC={KeyDataC} dataM={KeyDataM} Url={Url_messages}/>
                <GraphContConv id={ID} Url={Url} />
                <Cards id={ID} type_id={TYPE_ID} Key={KeyMT} Url={Url_totalMU} Data={DataMT} />
                <Cards id={ID} type_id={TYPE_ID} Key={KeyMC} Url={Url_totalMC} Data={DataMC} />
                
            </div>
        
    );
};
export default BoardUser;