import React, { useRef, useState, useEffect} from 'react';
import { PieChart, Pie, Cell, Sector, Legend } from 'recharts';
import { useCSSVariable } from '../../../utils/Colors';
const Radar = ({ id,Url }) => {

  const RADIAN = Math.PI / 180;
  const refDiv = useRef(null);
  const [chartSize, setChartSize] = useState(300);
  const [data, setData] = useState([]);

  /*Default date */
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [selectedDate, setSelectedDate] = useState({ month: currentMonth, year: currentYear });
  const [stats, setStats] = useState({ mean: 0, std: 0 });
  const [totalMessages, setTotalMessages] = useState(0);
  const [totalActiveDays, setTotalActiveDays] = useState(0);

  /*Color Palette call */
  const blue = useCSSVariable('--blue');
  const middlegray = useCSSVariable('--middlegray');
  const pink = useCSSVariable('--pink');
  const subtitle = useCSSVariable('--subtitle');
  const title = useCSSVariable('--title');
  const COLORS = {
    'Bajo': middlegray,
    'Medio': blue,
    'Alto': pink
  };

  /*Data obtain*/
  useEffect(() => {
    fetch(Url)
      .then(response => response.json())
      .then(jsonData => {
        if (!Array.isArray(jsonData)) {
          return;
        }
        /* Defines frequency, mean and standard deviation, for range the messages into a radar */
        const frequencies = jsonData.map(item => item.total_conversations / item.active_days);
        const mean = frequencies.reduce((acc, val) => acc + val, 0) / frequencies.length;
        const std = Math.sqrt(frequencies.map(f => Math.pow(f - mean, 2)).reduce((acc, val) => acc + val, 0) / frequencies.length);
        setStats({ mean, std });
        setData(jsonData);
      });
    /*Responsive container takes container dimensions for resizes */
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          requestAnimationFrame(() => {
            let newSize=Math.min(entry.contentRect.width, entry.contentRect.height);
            if (window.innerHeight <= 500) {
              newSize = Math.min(entry.contentRect.width, ((entry.contentRect.height)-entry.contentRect.height/9));
            }
            if (window.innerHeight <= 390) {
              newSize = Math.min(entry.contentRect.width, ((entry.contentRect.height)-entry.contentRect.height/15));
            }
            setChartSize(newSize);
          });
        }
      });
    resizeObserver.observe(refDiv.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [id, Url]);

/*Data clasification*/
  const monthData = data.filter(d => d.month === selectedDate.month && d.year === selectedDate.year).map(item => {
    const frequency = item.total_conversations / item.active_days;
    let category;
    let active_d=item.active_days;
    if (frequency < stats.mean - stats.std) {
      category = 'Bajo';
    } else if (frequency > stats.mean + stats.std) {
      category = 'Alto';  
    } else {
      category = 'Medio';
    }
    return { name: category, value: item.total_conversations, freq: frequency, active:active_d };
  });

/*Frecuency definition in the needle*/
  const needleValue = monthData.length > 0 ? monthData[0].freq : 0;

  /* Calculate the total messages and active days for the selected date and client*/
  useEffect(() => {
    const filteredData = data.filter(d => d.month === selectedDate.month && d.year === selectedDate.year);
    const totalMessagesCalc = filteredData.reduce((acc, cur) => acc + cur.total_conversations, 0);
    const totalActiveDaysCalc = filteredData.reduce((acc, cur) => acc + cur.active_days, 0);

    setTotalMessages(totalMessagesCalc);
    setTotalActiveDays(totalActiveDaysCalc);
  }, [data, selectedDate]);

  /*Calculate the needle angle based on the provided value*/
  const calculateNeedlePosition = (value) => {
    const total = stats.mean + 3 * stats.std; 
    const angle = (value / total) * 180;
    return angle;
  };

  /*Round numbers in two decimal */
  function roundToTwo(num) {
    return parseFloat(num.toFixed(2));
}

  /*Calculate the needle angle based on the provided value */
  const needle = (value, cx, cy, innerRadius, outerRadius) => {
    const angle = calculateNeedlePosition(value);
    const length = (innerRadius + 2 * outerRadius) / 4;
    const lengthT = (innerRadius + 2 * outerRadius) / 3.5;
    const sin = Math.sin(RADIAN * (180 - angle));
    const cos = Math.cos(RADIAN * (180 - angle));
    const r = 5;
    const x0 = cx;
    const y0 = cy;
    const Fr = roundToTwo(needleValue);
    const ancho = 1;
    let xba, yba, xbb, ybb;
  
    /*Adjust the needle's anchor points based on the angle */
    if ((angle >= 0 && angle <= 60) || (angle >= 120 && angle <= 180)) {
      xba = x0;
      yba = y0 - r;
      xbb = x0;
      ybb = y0 + r;
    } else {
      xba = x0 - r;
      yba = y0;
      xbb = x0 + r;
      ybb = y0;
    }
  
    const xp = x0 + length * cos;
    const yp = y0 - length * sin;
    /*Text Needle */
    const textX = x0 + lengthT * cos; // Adding a value to x for extra alignment
    const textY = y0 - lengthT * sin+10;
    const textRotation = angle - 90;
    let fontSize = '75%';
    if (window.innerHeight <= 500) {
      fontSize = '1.6vh';
    }
  
    if (window.innerHeight <= 390) {
      fontSize = '1.5vh';
    }
  
    return (
      <g>
        <circle cx={cx} cy={cy} r={r} fill="#FFD600" stroke="#FFD600" />
        <path d={`M${xba} ${yba} L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#FFD600" strokeWidth={ancho} fill={"#FFD600"} />
        <text
          x={textX}
          y={textY}
          fill="white"
          fontSize={fontSize}
          fontWeight="bold"
          textAnchor="end"
          alignmentBaseline="end"
          transform={`rotate(${textRotation},${textX},${textY})`}
        >
          {`${Fr} m/da`}
        </text>
      </g>
    );
  };
  

  /*Customize legend bellow graph */
  const renderLegend = (props) => {
    const { payload } = props;
    let fontSize = '70%'; 
    let fontSizeP = '70%'; 
    let marginTop= -30;
    let marginTopP= -6;
    let marginRight=30;
    let circleCx = '5';
    let circleCy = '5';
    let circleR = '5';
    
    if (window.innerHeight <= 500) {
      fontSize = '1.6vh'; 
      fontSizeP = '1.5vh'; 
      marginTop= -10;
      marginTopP= -4;
      circleCx = '4';
      circleCy = '4';
      circleR = '4';
      marginRight=5;
      
    }
    if (window.innerHeight <= 390) {
      fontSize = '1.5vh'; 
      marginTop= -8;
      marginTopP= -5;
      circleCx = '3.5';
      circleCy = '3.5';
      circleR = '3.5';
      marginRight=5;
      
    }
    
    return (
      <ul style={{
        listStyle: 'none',
        margin: 0,
        padding: 0,
        display: 'flex', 
        justifyContent: 'center', 
        flexWrap: 'wrap'
      }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ 
            display: 'flex', 
            alignItems: 'center', 
            marginRight,
            marginBottom: 4,
            marginTop
          }}>
            <svg height="10" width="10">
              <circle cx={circleCx} cy={circleCy} r={circleR} fill={entry.color} />
            </svg>
            <span style={{ marginLeft: 3, fontSize, fontWeight: '600', color: title }}>{entry.value}</span>
          </li>
        ))}
        <p 
          style={
            { 
              marginTop: marginTopP,
              fontSize: fontSizeP,
              color: pink,
              fontWeight: 'bold',
              marginRight
            }
          }>*m/da = Mensajes /dias Activos</p>
      </ul>
    );
  };

/*Customize labels on the graph */
  const renderCustomizedLabel = ({
    cx, cy, outerRadius,
  }) => {
    const x = cx;
    let y = cy - outerRadius - 10; 
    const fill = subtitle; 
    let fontSize = '70%'; 
    if (window.innerHeight <= 500) {
      fontSize = '1.8vh'; 
      y += 5;
    }
    if (window.innerHeight <= 390) {
      fontSize = '1.7vh'; 
      y += 1;
    }
    return (
      <text
        x={x}
        y={y}
        fill={fill}
        textAnchor="middle" 
        dominantBaseline="middle" 
        style={{ fontWeight: 'bold', fontSize }}
      >
        {`${totalMessages} mensajes / ${totalActiveDays} días activos`}
      </text>
    );
  };

  /*Visual definition of ranges in the Radar*/
  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius } = props;
    const maxAngle = 180;
    const sectionAngle = maxAngle / 3; // Dividiendo en 3 secciones iguales
  
    return (
      <g>
        {/* Low sector */}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={maxAngle - sectionAngle}
          endAngle={maxAngle}
          fill={COLORS['Bajo']}
          stroke="white"
          strokeWidth={1}
        />
        {/* Middle sector */}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={maxAngle - 2 * sectionAngle}
          endAngle={maxAngle - sectionAngle}
          fill={COLORS['Medio']}
          stroke="white"
          strokeWidth={1}
        />
        {/* High sector */}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={0}
          endAngle={maxAngle - 2 * sectionAngle}
          fill={COLORS['Alto']}
          stroke="white"
          strokeWidth={1}
        />
        {/* Needle to indicate the current value */}
        {needle(needleValue, cx, cy-10, innerRadius, outerRadius)}
      </g>
    );
  };
  
  return (
    <div  ref={refDiv} className='RadarContent' >
      
      <div className='titulo'>
        <h2>Frecuencia de las conversaciones</h2>
      </div>

      <div className="filtroRadarC">
        <div className='first'>
          <div className="container-input">
            <txt>Mes</txt>
            <select 
              value={`${selectedDate.month}-${selectedDate.year}`}
              onChange={e => {
                const [month, year] = e.target.value.split('-').map(Number);
                setSelectedDate({ month, year });
              }}
            >
              {Array.from(new Set(data.map(item => `${item.month}-${item.year}`))).map(date => {
                const [month, year] = date.split('-').map(Number);
                const monthName = new Date(year, month - 1).toLocaleString('default', { month: 'long' });
                return (
                  <option key={date} value={date}>
                    {`${monthName.charAt(0).toUpperCase() + monthName.slice(1)} ${year}`}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div  className="large-div">
        <div className="espacioabajo">
        <PieChart width={chartSize} height={chartSize}>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={monthData}
            cx={chartSize / 2}
            cy={chartSize / 2}
            label={renderCustomizedLabel}
            innerRadius={chartSize / 4.4}
            outerRadius={chartSize / 2.2}
            fill="#8884d8"
            stroke="none"
            activeIndex={0}
            activeShape={renderActiveShape}
          >
            {monthData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
              <Legend
                  content={renderLegend}
                  align="center"
                  verticalAlign="bottom"
                  layout="horizontal"
                  wrapperStyle={{
                    position: 'absolute',
                    top:0,
                    paddingTop: '64%',
                    paddingLeft:'10%'
                    
                  }}
                  payload={[
                    { value: 'Bajo', type: 'square', color: middlegray },
                    { value: 'Medio', type: 'square', color: blue },
                    { value: 'Alto', type: 'square', color: pink }
                  ]}
                />
        </PieChart>
        </div>
      </div>
    </div>
  );
};

export default Radar;